.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-content {
  flex: 1;
  width: 100%;
  max-width: 640px;
  text-align: left;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
}

/* ProductGrid styles */
.product-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.product-card {
  overflow: hidden;
}

.product-image {
  width: 100%;
  object-fit: cover;
}

.product-info {
  padding: 4px 0px 8px 0px;
  display: flex;
  flex-direction: row;
}

.product-name {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  margin: 4px 0px;
}

.product-detail {
  font-size: 12px;
  color: #878787;
}

.product-price {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.buy-now-button {
  background-color: #fff;
  color: #0b99ff;
  border: none;
  font-size: 14px;
  margin: 4px 0px;
  padding: 0px;
}

.buy-now-button:hover {
  cursor: pointer;
  color: #2383e2;
}

.product-sold {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  margin: 4px 0px;
  color: red;
}

header {
  margin-top: 2rem;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 1.4;
}

article {
  margin-top: 20px;
  display: flex;
  gap: 18px;
  flex-direction: column;
}

h1 {
  font-size: 16px;
  font-weight: 600;
}

a {
  text-decoration: none;
  padding: 0 !important;
  background-color: transparent;
  color: #000;
  transition: background 0.1s ease-in-out, border-bottom-color 0.1s ease-in,
    box-shadow 0.1s ease-in;
  border-bottom: 1px solid #ddd;
  display: inline-flex;
  align-items: baseline;
  position: relative;
  font-size: 16px;
  line-height: 1.4;
}

a:hover {
  border-bottom-color: #000;
}

a.active {
  border-bottom-color: #000;
  border-bottom-width: 1px;
}

a.inactive {
  border-bottom-color: #fff;
  border-bottom-width: 1px;
}

a.inactive:hover {
  border-bottom-color: #000;
}

/* Footer.js styles */

footer {
  border-top: 1px solid #f7f7f7;
  padding: 1rem 0;
  background-color: #f7f7f7;
  text-align: left;
  width: 100%;
  font-size: 11px;
  padding: 12px 24px;
  box-sizing: border-box;
  align-items: center;
  margin-top: auto; /* This pushes the footer to the bottom */
  display: flex;
}

.footer {
  margin-top: 1rem;
  font-size: 12px;
}

.footer-link {
  text-decoration: none;
  color: inherit;
  transition: color 0.2s ease-in-out;
}

.footer-link.active {
  font-weight: bold;
}

.footer-divider {
  color: #a0aec0;
  margin: 0 0.25rem;
}

/* Responsive media styles */

@media (max-width: 768px) {
  .App-content {
    max-width: 100%;
    padding: 0 16px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-grid {
    grid-template-columns: 1fr;
  }

  footer {
    padding: 12px 16px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 14px;
  }

  a {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  footer {
    font-size: 11px;
  }
}
